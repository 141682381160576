// src/components/Header/JuriLogo.js
import React from 'react';

const JuriLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="30"
      viewBox="0 0 100 30"
      fill="none"
    >
      <text
        x="0"
        y="20"
        fill="currentColor"
        fontFamily="Arial, sans-serif"
        fontSize="40"
        fontWeight="bold"
      >
        JURI
      </text>
    </svg>
  );
};

export default JuriLogo;

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, CircularProgress, Backdrop, Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAnswerFromOpenAI } from '../../utils/openaiAPI';
import { storeAnswer } from '../../redux/actions/answerActions';
import { fetchPrompts } from '../../utils/admin-prompts';
import AnswerDisplay from './AnswerComponents/AnswerDisplay';
import QuestionInput from './QuestionInput';

const MainWindow = ({ selectedItem, setSelectedItem, isNewCard }) => {
    const [selectedFilter, setSelectedFilter] = useState({ main: '', sub: 'original' });
    const [displayedAnswer, setDisplayedAnswer] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState(null);
    const [isLoadingPrompts, setIsLoadingPrompts] = useState(true);
    const answers = useSelector((state) => state.answers);
    const dispatch = useDispatch();
    const [prompts, setPrompts] = useState(null);

    // Fetch prompts on component mount
    useEffect(() => {
        const loadPrompts = async () => {
            setIsLoadingPrompts(true);
            try {
                const data = await fetchPrompts();

                // Validate required prompt fields
                if (!data.filterPrompt || !data.formattingPrompt) {
                    throw new Error('Required prompts (filterPrompt or formattingPrompt) are missing in configuration');
                }

                setPrompts(data);
                setError(null);
            } catch (error) {
                console.error('Failed to fetch prompts:', error);
                setError('The prompt configuration is corrupted or missing. Please contact the administrator to restore the prompts.');
            } finally {
                setIsLoadingPrompts(false);
            }
        };

        loadPrompts();
    }, []);

    // Memoized function to determine answer type
    const determineAnswerType = useCallback(() => {
        return selectedFilter.sub ? selectedFilter.sub : 'original';
    }, [selectedFilter]);

    // Memoized function to fetch or generate an answer
    const fetchOrGenerateAnswer = useCallback(async (questionId, answerType) => {
        if (!prompts?.filterPrompt || !prompts?.formattingPrompt) {
            setError('Cannot generate answer without proper prompt configuration. Please contact the administrator.');
            return;
        }

        if (!answers[`${questionId}-${answerType}`]) {
            setIsGenerating(true);
            try {
                const originalAnswer = answers[`${questionId}-original`];

                if (!originalAnswer) {
                    console.error('Original answer not found in the store.');
                    return;
                }

                const originalText = JSON.stringify(originalAnswer);
                const filterPromptText = `#Originale Frage: ${selectedItem.text}\n\n#Originale Antwort: ${originalText}\n\n#Neue Nutzeranfrage: ${prompts.filterPrompt[answerType]}\n\n#JSON Formatierungsvorgabe${prompts.formattingPrompt}`;
                // console.log('filterPromptText', filterPromptText);
                const generatedAnswer = await fetchAnswerFromOpenAI(filterPromptText);

                const paragraphs = Object.entries(generatedAnswer['answer']).map(([section, content]) => ({
                    section,
                    content,
                    id: Math.random().toString(),
                }));

                dispatch(storeAnswer({ questionId, paragraphs, answerType }));
                setDisplayedAnswer({ questionId, paragraphs, answerType });
            } catch (error) {
                console.error(`Failed to generate ${answerType} answer:`, error);
                setError('An error occurred while generating the answer. Please try again.');
            } finally {
                setIsGenerating(false);
            }
        } else {
            setDisplayedAnswer(answers[`${questionId}-${answerType}`]);
        }
    }, [answers, prompts, selectedItem, dispatch]);

    useEffect(() => {
        if (selectedItem && prompts) {
            const answerType = determineAnswerType();
            fetchOrGenerateAnswer(selectedItem.id, answerType);
        }
    }, [selectedItem, prompts, determineAnswerType, fetchOrGenerateAnswer]);

    const handleNewCardCreated = (newCard) => {
        setSelectedItem(newCard);
    };

    const handleFilterSelect = (mainOption, subOption) => {
        setSelectedFilter({ main: mainOption, sub: subOption });
    };

    const handleCloseSnackbar = () => {
        setError(null);
    };

    // Show loading state while prompts are being fetched
    if (isLoadingPrompts) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    // Show error state if prompts couldn't be loaded
    if (!prompts) {
        return (
            <Alert 
                severity="error" 
                sx={{ m: 2 }}
            >
                The prompt configuration is missing or corrupted. Please contact the administrator to restore the prompts.
            </Alert>
        );
    }

    return (
        <Box p={2}>
            <Backdrop
                open={isGenerating}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {isNewCard && !selectedItem ? (
                <QuestionInput 
                    onNewCardCreated={handleNewCardCreated} 
                    setSelectedItem={setSelectedItem} 
                />
            ) : selectedItem ? (
                <Box>
                    <Typography variant="h5">{selectedItem.text || selectedItem}</Typography>
                    {displayedAnswer ? (
                        <AnswerDisplay 
                            answer={displayedAnswer} 
                            handleFilterSelect={handleFilterSelect} 
                            availableFilters={prompts.filterPrompt} // Pass available filters from prompts
                        />
                    ) : (
                        <Typography variant="body1">
                            No answer available for the selected filters.
                        </Typography>
                    )}
                </Box>
            ) : (
                <Typography variant="h6">
                    Wählen Sie eine Karteikarte aus oder erstellen Sie eine neue.
                </Typography>
            )}

            <Snackbar
                open={Boolean(error)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity="error" 
                    sx={{ width: '100%' }}
                >
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MainWindow;

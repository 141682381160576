import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import { submitNewCard } from '../../redux/actions/questionActions';
import { storeAnswer } from '../../redux/actions/answerActions';
import { fetchAnswerFromOpenAI } from '../../utils/openaiAPI';
import { fetchPrompts } from '../../utils/admin-prompts'; // Import the fetch function

const QuestionInput = ({ onNewCardCreated, setSelectedItem }) => {
    const [newCardText, setNewCardText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [supplementalPrompt, setSupplementalPrompt] = useState(null);
    const [isLoadingPrompts, setIsLoadingPrompts] = useState(true);
    const dispatch = useDispatch();

    // Fetch prompts when component mounts
    useEffect(() => {
        const loadPrompts = async () => {
            try {
                const prompts = await fetchPrompts();
                
                // Check if the prompts object has the required field
                if (!prompts.supplementalPrompt) {
                    throw new Error('supplementalPrompt not found in prompts configuration');
                }
                
                setSupplementalPrompt(prompts.supplementalPrompt);
                setIsLoadingPrompts(false);
            } catch (err) {
                console.error('Failed to load prompts:', err);
                setError('The prompt configuration is corrupted or missing. Please contact the administrator to restore the prompts.');
                setIsLoadingPrompts(false);
            }
        };

        loadPrompts();
    }, []);

    const handleNewCardSubmit = async () => {
        // Check if prompts are properly loaded
        if (!supplementalPrompt) {
            setError('Cannot submit without proper prompt configuration. Please contact the administrator.');
            return;
        }

        if (newCardText.trim() !== '') {
            setIsSubmitting(true);
            const cardId = Date.now().toString();

            dispatch(submitNewCard(newCardText, cardId));

            try {
                const combinedPrompt = `${supplementalPrompt}\n\n${newCardText}`;
                const generatedAnswer = await fetchAnswerFromOpenAI(combinedPrompt);
                // console.log('generatedAnswer', generatedAnswer['answer']);
                
                const paragraphs = Object.entries(generatedAnswer['answer']).map(([section, content]) => ({
                    section,
                    content,
                    id: Math.random().toString(),
                }));
                
                dispatch(storeAnswer({ questionId: cardId, paragraphs, answerType: 'original' }));
                
                onNewCardCreated({ id: cardId, text: newCardText });
                setSelectedItem({ id: cardId, text: newCardText });
                setNewCardText('');
            } catch (error) {
                console.error('Failed to fetch answer from Azure OpenAI:', error);
                setError('An error occurred while fetching the answer. Please try again.');
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleCloseSnackbar = () => {
        setError(null);
    };

    // Show loading state while prompts are being fetched
    if (isLoadingPrompts) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    // Show error state if prompts couldn't be loaded
    if (!supplementalPrompt) {
        return (
            <Alert 
                severity="error" 
                sx={{ mt: 3 }}
            >
                The prompt configuration is missing or corrupted. Please contact the administrator to restore the prompts.
            </Alert>
        );
    }

    return (
        <Box>
            <Backdrop
                open={isSubmitting}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <TextField
                fullWidth
                label="Neue Karteikarte"
                value={newCardText}
                onChange={(e) => setNewCardText(e.target.value)}
                variant="outlined"
                multiline
                rows={4}
                sx={{ mt: 2 }}
                disabled={isSubmitting}
            />
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleNewCardSubmit}
                disabled={isSubmitting || !supplementalPrompt}
            >
                Karteikarte hinzufügen
            </Button>

            <Snackbar
                open={Boolean(error)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity="error" 
                    sx={{ width: '100%' }}
                >
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default QuestionInput;
import React, { useState } from 'react';
import { Typography, Box, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ReactMarkdown from 'react-markdown';

// Function to safely remove citations
const removeCitations = (text) => {
    if (typeof text !== 'string') {
        return ''; // Return empty string if input is not a string
    }
    return text.replace(/【\d+:\d+†[^】]+】/g, '');
};

// Define a mapping for section titles
const sectionTitleMapping = {
    problemstellung: 'Problemstellung',
    erklaerung: 'Erklärung',
    gesetzestext_und_definitionen: 'Gesetzestext und Definitionen',
    systematische_einordnung: 'Systematische Einordnung',
    beispielsfall: 'Beispielsfall',
    merksatz: 'Merksatz',
    abgrenzung: 'Abgrenzung',
    relevantes_urteil: 'Relevantes Urteil',
    stichpunktartige_eingliederung: 'Stichpunktartige Eingliederung',
    formulierungsbeispiel: 'Formulierungsbeispiel',
};

const AnswerParagraph = ({ paragraph, index, updateParagraph, isModified }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(
        typeof paragraph?.content === 'string' ? paragraph.content : ''
    );

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleChange = (event) => {
        setEditedText(event.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (paragraph?.id) {
            updateParagraph(paragraph.id, editedText);
        }
    };

    // Safely clean content
    const cleanedContent = removeCitations(paragraph?.content);

    // Map section to a display name, defaulting to 'Section' if not in the mapping
    const sectionTitle = sectionTitleMapping[paragraph?.section] || 'Section';

    return (
        <Box
            mt={1}
            p={2}
            sx={{
                border: `1px solid ${isModified ? 'orange' : '#ddd'}`,
                borderRadius: '8px',
                mb: 2,
                position: 'relative',
                backgroundColor: 'background.paper'
            }}
        >
            <Typography 
                variant="subtitle1" 
                sx={{ 
                    fontWeight: 'bold', 
                    display: 'flex', 
                    alignItems: 'center',
                    mb: 1
                }}
            >
                {sectionTitle}
                <IconButton
                    size="small"
                    onClick={handleEdit}
                    sx={{ ml: 1, cursor: 'pointer' }}
                >
                    <EditIcon fontSize="small" />
                </IconButton>
            </Typography>

            {isEditing ? (
                <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    value={editedText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            padding: '10px',
                        },
                    }}
                />
            ) : (
                <Box
                    onClick={handleEdit}
                    sx={{
                        cursor: 'text',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                            borderRadius: '4px',
                            transition: 'background-color 0.2s ease',
                        },
                        p: 1
                    }}
                >
                    {cleanedContent ? (
                        <ReactMarkdown>{cleanedContent}</ReactMarkdown>
                    ) : (
                        <Typography color="text.secondary">
                            No content
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default AnswerParagraph;

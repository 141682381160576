import { SUBMIT_QUESTION, DELETE_QUESTION } from '../actions/types';
import QuestionModel from '../../models/QuestionModel';

const initialState = [];

// Reducer for questions
const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_QUESTION:
      // Add the new question and sort the list by ID (descending order)
      return [...state, new QuestionModel(action.payload.id, action.payload.text)].sort((a, b) => parseInt(b.id) - parseInt(a.id));

    case DELETE_QUESTION:
      // Remove the question by ID and keep the remaining sorted
      return state.filter((question) => question.id !== action.payload).sort((a, b) => parseInt(b.id) - parseInt(a.id));

    default:
      return state;
  }
};

export default questionReducer;

import React, { useState, useEffect } from "react";
import { 
    Button, 
    Box, 
    Typography, 
    Snackbar, 
    Alert, 
    AlertTitle,
    TextField,
    Paper,
    Container,
    Fade,
    Backdrop,
    CircularProgress,
  } from "@mui/material";
import { DiffEditor } from "@monaco-editor/react";
import { fetchPrompts, updatePrompts, fetchOriginalPrompts } from "../../utils/admin-prompts";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';

const AdminPromptsEditor = () => {
  const [originalPrompts, setOriginalPrompts] = useState("");
  const [prompts, setPrompts] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      const loadPrompts = async () => {
        setIsLoading(true);
        setLoadingMessage("Loading prompts...");
        try {
          const data = await fetchPrompts();
          const formattedData = JSON.stringify(data, null, 2);
          setOriginalPrompts(formattedData);
          setPrompts(formattedData);
        } catch (err) {
          console.error("Failed to fetch prompts:", err);
          setError("Failed to load prompts. Please try again.");
        } finally {
          setIsLoading(false);
          setLoadingMessage("");
        }
      };
      loadPrompts();
    }
  }, [isAuthenticated]);

  // Handle restore original prompts
  const handleRestore = async () => {
    setIsRestoring(true);
    setIsLoading(true);
    setLoadingMessage("Restoring original prompts...");
    setError(null);

    try {
      const originalData = await fetchOriginalPrompts();
      const formattedData = JSON.stringify(originalData, null, 2);
      
      console.log("Restoring original prompts:", formattedData);
      
      // Update Azure with original data
      await updatePrompts(originalData);
      
      // Update both editors with the restored data
      setOriginalPrompts(formattedData);
      setPrompts(formattedData);
      setSuccess(true);
    } catch (err) {
      console.error("Failed to restore original prompts:", err);
      setError("Failed to restore original prompts. Please try again.");
    } finally {
      setIsRestoring(false);
      setIsLoading(false);
      setLoadingMessage("");
    }
  };

  // Handle save
  const handleSave = async () => {
    setIsSaving(true);
    setIsLoading(true);
    setLoadingMessage("Saving changes...");
    setError(null);
  
    try {
      console.log("Saving prompts content:", prompts);
      
      const savedData = await updatePrompts(JSON.parse(prompts));
      const formattedData = JSON.stringify(savedData, null, 2);
      
      console.log("Received saved data:", formattedData);
      
      setOriginalPrompts(formattedData);
      setPrompts(formattedData);
      setSuccess(true);
    } catch (err) {
      console.error("Failed to save prompts:", err);
      setError("Failed to save prompts. Please check your JSON format and try again.");
    } finally {
      setIsSaving(false);
      setIsLoading(false);
      setLoadingMessage("");
    }
  };

  // Password Authentication
  const handlePasswordSubmit = () => {
    const validPassword = process.env.REACT_APP_ADMIN_PASSWORD;
    if (password === validPassword) {
      setIsAuthenticated(true);
      setError(null);
    } else {
      setError("Invalid password. Please try again.");
    }
  };

  // Handle editor content change - Fixed for DiffEditor
  const handleEditorDidMount = (editor, monaco) => {
    const modifiedEditor = editor.getModifiedEditor();
    modifiedEditor.onDidChangeModelContent(() => {
      const newValue = modifiedEditor.getValue();
      setPrompts(newValue);
      console.log("Editor content updated:", newValue);
    });
  };


  const InstructionsPanel = () => (
    <Paper
      elevation={2}
      sx={{
        mb: 3,
        p: 3,
        background: 'linear-gradient(to right, #fff9c4, #fff)',
        border: '1px solid #ffd54f',
      }}
    >
      <Typography variant="h6" gutterBottom color="primary" sx={{ fontWeight: 600 }}>
        ⚠️ Important Instructions for Prompt Editor
      </Typography>
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500, color: '#d32f2f' }}>
          Warning: Changes to these prompts directly affect how the AI responds!
        </Typography>
        <Typography paragraph>
          This editor allows you to modify the AI's behavior by changing its prompts. Please read these instructions carefully before making any changes.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
          How to Use the Editor:
        </Typography>
        <Typography component="div">
          <ul>
            <li>The left side shows the current prompts in use</li>
            <li>The right side is where you can make changes</li>
            <li>All content must be valid JSON format</li>
            <li>Maintain the existing structure while editing values</li>
          </ul>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500, color: '#1976d2' }}>
          Available Actions:
        </Typography>
        <Typography component="div">
          <ul>
            <li><strong>Save Changes</strong> - Applies your modifications to the live system</li>
            <li><strong>Restore Original</strong> - Reverts all prompts back to their original, tested versions</li>
          </ul>
        </Typography>
      </Box>

      <Alert severity="warning" sx={{ mt: 2 }}>
        <AlertTitle>Important Safety Note</AlertTitle>
        If after making changes you notice that:
        <ul>
          <li>The AI's responses become inappropriate or incorrect</li>
          <li>The AI stops following instructions properly</li>
          <li>The system shows unexpected behavior</li>
        </ul>
        Please use the "Restore Original" button immediately to revert to the last known working version.
      </Alert>
    </Paper>
  );

  return (
    <>
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
          gap: 2
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" size={60} thickness={4} />
        {loadingMessage && (
          <Typography variant="h6" component="div">
            {loadingMessage}
          </Typography>
        )}
      </Backdrop>

      <Container maxWidth="xl">
        <Box
          sx={{
            width: '100%',
            height: 'calc(160vh - 100px)',
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!isAuthenticated ? (
            <Fade in={true}>
              <Paper 
                elevation={4}
                sx={{
                  maxWidth: '400px',
                  mx: 'auto',
                  mt: 8,
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: 2,
                  background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
                }}
              >
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    width: 56,
                    height: 56,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                    boxShadow: 2,
                  }}
                >
                  <LockOutlinedIcon fontSize="large" />
                </Box>

                <Typography 
                  variant="h5" 
                  component="h1" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 500,
                    color: 'text.primary',
                    mb: 3,
                  }}
                >
                  Admin Access
                </Typography>

                <TextField
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'primary.main',
                      },
                    },
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handlePasswordSubmit();
                    }
                  }}
                />

                <Button 
                  variant="contained" 
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={handlePasswordSubmit}
                  sx={{
                    py: 1.5,
                    textTransform: 'none',
                    fontWeight: 500,
                    boxShadow: 2,
                    '&:hover': {
                      boxShadow: 4,
                    },
                  }}
                >
                  Sign In
                </Button>

                {error && (
                  <Alert 
                    severity="error" 
                    onClose={() => setError(null)} 
                    sx={{ 
                      mt: 3,
                      width: '100%',
                      borderRadius: 1,
                    }}
                  >
                    {error}
                  </Alert>
                )}
              </Paper>
            </Fade>
          ) : (
            <>
              <InstructionsPanel />
              <Paper
                elevation={4}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 2,
                  overflow: 'hidden',
                  background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: 'white',
                  }}
                >
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 500,
                      color: 'text.primary',
                    }}
                  >
                    Prompt Editor
                  </Typography>

                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleRestore}
                      disabled={isRestoring || isSaving}
                      startIcon={<RestoreIcon />}
                      sx={{
                        px: 3,
                        py: 1,
                        textTransform: 'none',
                        fontWeight: 500,
                        boxShadow: 1,
                        '&:hover': {
                          boxShadow: 2,
                        },
                      }}
                    >
                      {isRestoring ? "Restoring..." : "Restore Original"}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      disabled={isSaving || isRestoring}
                      startIcon={<SaveIcon />}
                      sx={{
                        px: 3,
                        py: 1,
                        textTransform: 'none',
                        fontWeight: 500,
                        boxShadow: 2,
                        '&:hover': {
                          boxShadow: 4,
                        },
                      }}
                    >
                      {isSaving ? "Saving..." : "Save Changes"}
                    </Button>
                  </Box>
                </Box>

                {error && (
                  <Alert 
                    severity="error" 
                    onClose={() => setError(null)}
                    sx={{ mx: 3, mt: 2 }}
                  >
                    {error}
                  </Alert>
                )}

                <Box 
                  sx={{ 
                    flex: 1,
                    minHeight: 0,
                    m: 3,
                    borderRadius: 1,
                    overflow: 'hidden',
                    boxShadow: 'inset 0 0 10px rgba(0,0,0,0.1)',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <DiffEditor
                    height="100%"
                    original={originalPrompts}
                    modified={prompts}
                    language="json"
                    onMount={handleEditorDidMount}
                    options={{
                      fontSize: 14,
                      readOnly: false,
                      renderSideBySide: true,
                      minimap: { enabled: false },
                      automaticLayout: true,
                      wordWrap: "on",
                      theme: "vs-light",
                    }}
                  />
                </Box>
              </Paper>
            </>
          )}

          <Snackbar
            open={success}
            autoHideDuration={3000}
            onClose={() => setSuccess(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert 
              severity="success" 
              variant="filled"
              onClose={() => setSuccess(false)}
              sx={{ boxShadow: 2 }}
            >
              {isRestoring ? "Original prompts restored successfully!" : "Prompts updated successfully!"}
            </Alert>
          </Snackbar>
        </Box>
      </Container>
    </>
  );
};

export default AdminPromptsEditor;
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import AnswerParagraph from './AnswerParagraph';
import { storeAnswer } from '../../../redux/actions/answerActions'; // Import action to persist answer
import NestedMenu from './NestedFilters'; // Import the new NestedMenu component

const AnswerDisplay = ({ answer, handleFilterSelect }) => {
    const dispatch = useDispatch();

    // Local state to manage the paragraphs and track modifications
    const [paragraphs, setParagraphs] = useState(answer ? answer.paragraphs : []);
    const [modified, setModified] = useState(answer ? answer.modified : []); // Track modified status
    const [originalParagraphs, setOriginalParagraphs] = useState(answer ? [...answer.paragraphs] : []); // Store the original content for comparison
    const [isModified, setIsModified] = useState(false); // Track if any paragraph is modified

    // Sync paragraphs with the answer prop using useEffect
    useEffect(() => {
        if (answer) {
            setParagraphs(answer.paragraphs);
            setOriginalParagraphs([...answer.paragraphs]); // Store original paragraphs for comparison
            setModified(answer.modified);
            setIsModified(false); // Reset modification flag when a new answer is loaded
        }
    }, [answer]);

    // Function to check if any paragraph was modified
    const checkForModifications = (updatedParagraphs) => {
        const hasChanges = updatedParagraphs.some((para, idx) => para.content !== originalParagraphs[idx].content);
        setIsModified(hasChanges); // Mark as modified only if changes are made
    };

    // Function to update a paragraph's content and mark it as modified
    const updateParagraph = (id, newText) => {
        // Update paragraphs with new content
        const updatedParagraphs = paragraphs.map((para) =>
            para.id === id ? { ...para, content: newText } : para
        );

        setParagraphs(updatedParagraphs); // Set the updated paragraphs state

        // Only mark as modified if the new text is different from the original text
        const updatedModified = modified.map((mod, idx) =>
            updatedParagraphs[idx].id === id && updatedParagraphs[idx].content !== originalParagraphs[idx].content ? true : mod
        );
        setModified(updatedModified);

        // After state is updated, check if there are any modifications
        checkForModifications(updatedParagraphs); // Check if any paragraph was modified
    };

    // Handle saving the modified answer to Redux
    const handleSave = () => {
        const updatedAnswer = {
            questionId: answer.questionId,
            paragraphs,
            modified,
            answerType: answer.answerType, // Ensure the answerType is passed to identify the version (e.g., 'original')
        };
        dispatch(storeAnswer(updatedAnswer)); // Dispatch the updated answer with the correct answerType
        setIsModified(false); // Reset the modification flag after saving
    };

    return (
        <Card sx={{ mt: 2, padding: 2, backgroundColor: '#f5f5f5', borderRadius: '10px' }}>
            {/* Render the Nested Menu */}
            <NestedMenu onFilterSelect={handleFilterSelect} />
            <CardContent>
                {/* Render paragraphs if the answer exists */}
                {paragraphs.length > 0 ? (
                    paragraphs.map((para, index) => (
                        <AnswerParagraph
                            key={para.id}
                            paragraph={para}
                            index={index}
                            updateParagraph={updateParagraph} // Pass the update function to each paragraph
                            isModified={modified[index]} // Pass the modification status to each paragraph
                        />
                    ))
                ) : (
                    <Typography variant="body1">Loading answer...</Typography> // Fallback while the answer is being fetched/generated
                )}
            </CardContent>

            {/* Render "Speichern" button if any paragraph has been modified */}
            {isModified && (
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave} // Save all changes on click
                    >
                        Speichern
                    </Button>
                </Box>
            )}
        </Card>
    );
};

export default AnswerDisplay;
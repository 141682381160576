import React, { useState } from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Button,
    Box,
    Menu,
    MenuItem,
    Divider,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux'; // Import hooks from React-Redux
import { deleteQuestion } from '../../redux/actions/questionActions'; // Ensure this action is from the questions-related actions

const LeftPanel = ({ drawerWidth, setSelectedItem, setIsNewCard }) => {
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null); // Track the active (clicked) item
    const [contextMenu, setContextMenu] = useState(null); // Manage context menu state

    // Use the selector from the questions store
    const questions = useSelector((state) => state.questions); // Fetch questions from the questions store
    const dispatch = useDispatch();

    // Toggle the expandable history section
    const handleClick = () => {
        setOpen(!open);
    };

    const handleItemClick = (item) => {
        setSelectedItem(item);  // Pass the selected item to MainWindow
        setActiveItem(item);     // Highlight the clicked item
        setIsNewCard(false);     // Indicate this is not a new card input
    };

    const handleNewCardClick = () => {
        setActiveItem(null);     // Clear any previously selected item
        setIsNewCard(true);      // Indicate that the user wants to add a new card
        setSelectedItem(null);   // Clear the selected item
    };

    // Open context menu on right-click
    const handleContextMenu = (event, item) => {
        event.preventDefault(); // Prevent the default browser context menu
        setActiveItem(item); // Highlight the clicked item
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            item, // Keep track of the item being clicked
        });
    };

    // Close the context menu
    const handleClose = () => {
        setContextMenu(null);
    };

    // Delete the selected question
    const handleDelete = () => {
        dispatch(deleteQuestion(contextMenu.item.id)); // Dispatch delete action from questions store
        setContextMenu(null); // Close the menu after deletion
        setSelectedItem(null); // Clear the selection in MainWindow
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    top: '64px', // Adjust for the height of the header
                },
            }}
        >
            <Box sx={{ overflow: 'auto', height: 'calc(100% - 64px)' }}>
                <List>
                    {/* New Karteikarte */}
                    <ListItem>
                        <Button fullWidth variant="contained" color="primary" onClick={handleNewCardClick}>
                            Neue Karteikarte
                        </Button>
                    </ListItem>

                    {/* Meine Karteikarte with expandable history */}
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary={`Meine Karteikarten (${questions.length})`} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {questions.map((question, index) => (
                                <ListItem
                                    button
                                    key={index}
                                    sx={{
                                        pl: 4,
                                        backgroundColor: activeItem === question ? 'lightblue' : 'transparent', // Highlight active item
                                        '&:hover': {
                                            backgroundColor: 'lightgray', // Hover effect
                                        },
                                    }}
                                    onClick={() => handleItemClick(question)} // Set active item and pass to MainWindow
                                    onContextMenu={(e) => handleContextMenu(e, question)} // Right-click to open context menu
                                >
                                    <ListItemText primary={question.text} />
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                    <Divider />
                    {/* Static List (like in the screenshot) */}
                    {['BGB AT (20)', 'Schuldrecht AT (30)', 'Schuldrecht BT (40)', 'Familienrecht (5)', 'Erbrecht (5)', 'Sonstige'].map((item, index) => (
                        <ListItem
                            button
                            key={index}
                            sx={{
                                backgroundColor: activeItem === item ? 'lightblue' : 'transparent', // Highlight active item
                                '&:hover': {
                                    backgroundColor: 'lightgray', // Hover effect
                                },
                            }}
                            onClick={() => setSelectedItem(null)    } // Clear the selected item
                        >
                            <ListItemText primary={item} />
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Context menu for the Karteikarten items */}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Drawer>
    );
};

export default LeftPanel;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppLayout from './components/layouts/AppLayout';
import AdminPromptsEditor from './components/AdminPromptsEditor/AdminPromptsEditor';
import GlobalHeader from './components/Header/Header';


const App = () => {
    return (
        <Router>
            {/* Global Header that appears on all pages */}
            <GlobalHeader />

            {/* Routing for the app */}
            <Routes>
                <Route path="/" element={<AppLayout />} />
                <Route path="/admin/prompts" element={<AdminPromptsEditor />} />
                {/* Future routes can be added here */}
            </Routes>
        </Router>
    );
};

export default App;

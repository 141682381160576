import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  TextField, 
  Button, 
  Typography, 
  Alert,
  Fade,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LeftPanel from '../LeftPanel/LeftPanel';
import MainWindow from '../MainWindow/MainWindow';

const drawerWidth = 240;

const ProtectedAppLayout = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isNewCard, setIsNewCard] = useState(false);

  const handlePasswordSubmit = () => {
    const validPassword = process.env.REACT_APP_APP_PASSWORD;
    if (password === validPassword) {
      setIsAuthenticated(true);
      setError(null);
    } else {
      setError('Invalid password. Please try again.');
    }
  };

  if (!isAuthenticated) {
    return (
      <Fade in={true}>
        <Paper 
          elevation={4}
          sx={{
            maxWidth: '400px',
            mx: 'auto',
            mt: 8,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 2,
            background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
          }}
        >
          <Box
            sx={{
              bgcolor: 'primary.main',
              color: 'white',
              width: 56,
              height: 56,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
              boxShadow: 2,
            }}
          >
            <LockOutlinedIcon fontSize="large" />
          </Box>

          <Typography 
            variant="h5" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 500,
              color: 'text.primary',
              mb: 3,
            }}
          >
            App Access
          </Typography>

          <TextField
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handlePasswordSubmit();
              }
            }}
          />

          <Button 
            variant="contained" 
            color="primary"
            fullWidth
            size="large"
            onClick={handlePasswordSubmit}
            sx={{
              py: 1.5,
              textTransform: 'none',
              fontWeight: 500,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Sign In
          </Button>

          {error && (
            <Alert 
              severity="error" 
              onClose={() => setError(null)} 
              sx={{ 
                mt: 3,
                width: '100%',
                borderRadius: 1,
              }}
            >
              {error}
            </Alert>
          )}
        </Paper>
      </Fade>
    );
  }

  return (
    <Box>
      <Box display="flex">
        <LeftPanel
          drawerWidth={drawerWidth}
          setSelectedItem={setSelectedItem}
          setIsNewCard={setIsNewCard}
        />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <MainWindow
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            isNewCard={isNewCard}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProtectedAppLayout;
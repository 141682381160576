
// Fetch the SAS URL from environment variables
const blobUrl = process.env.REACT_APP_PROMPTS_URL;

// Function to fetch prompts from Azure Blob Storage
export const fetchPrompts = async () => {
  try {
    // console.log("Attempting to fetch from:", blobUrl);
    
    const response = await fetch(blobUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching prompts:", error);
    throw error;
  }
};

// Function to fetch original prompts from local file
export const fetchOriginalPrompts = async () => {
  try {
    console.log("Fetching original prompts from local file");
    const response = await fetch('/prompts.json');
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("Successfully fetched original prompts:", data);
    return data;
  } catch (error) {
    console.error("Error fetching original prompts:", error);
    throw error;
  }
};

// Function to validate JSON format
const validateJSON = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    console.error("Invalid JSON format:", error);
    return false;
  }
};

// Function to update prompts in Azure Blob Storage
export const updatePrompts = async (newPrompts) => {
  const jsonString = JSON.stringify(newPrompts, null, 2);
  
  if (!validateJSON(jsonString)) {
    throw new Error("Invalid JSON format. Please check and try again.");
  }

  try {
    console.log("Updating prompts with data:", jsonString);

    const response = await fetch(blobUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-version': '2020-04-08',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      },
      body: jsonString
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    console.log("Prompts successfully updated");
    return newPrompts;
  } catch (error) {
    console.error("Error updating prompts:", error);
    throw error;
  }
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'; // Import Provider to connect Redux with React
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate for redux-persist
import { store, persistor } from './redux/store'; // Import store and persistor from combined store
import ErrorBoundary from './components/common/ErrorBoundary'; // Import ErrorBoundary component

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        {/* Wrap App with Redux Provider to pass down the store */}
        <Provider store={store}>
            {/* PersistGate delays rendering until the persisted state is rehydrated */}
            <PersistGate loading={null} persistor={persistor}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();

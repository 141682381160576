// src/utils/openaiAPI.js
import axios from 'axios';

// Fetch answer from Azure OpenAI with retry logic
export const fetchAnswerFromOpenAI = async (combinedPrompt) => {
    const apiKey = process.env.REACT_APP_AZURE_OPENAI_API_KEY;
    const endpoint = process.env.REACT_APP_AZURE_OPENAI_ENDPOINT;

    if (!apiKey) {
        throw new Error('API key is not defined. Ensure REACT_APP_AZURE_OPENAI_API_KEY is set.');
    }

    if (!endpoint) {
        throw new Error('API endpoint is not defined. Ensure REACT_APP_AZURE_OPENAI_ENDPOINT is set.');
    }

    // console.log('Combined prompt:', combinedPrompt);
    // console.log('Using endpoint:', endpoint);

    const maxRetries = 5; // Maximum number of retry attempts
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            // Increment the attempt count
            attempt++;

            // Send the prompt to Azure OpenAI API
            const response = await axios.post(
                `${endpoint}`, // Use the full endpoint from the .env variable
                {
                    prompt: combinedPrompt
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': apiKey,
                    }
                }
            );
            // console.log('API Response:', response);

            // Try to parse the response JSON
            const parsedResponse = JSON.parse(response.data[0].content[0].text.value);

            return parsedResponse; // Return the parsed response if successful

        } catch (error) {
            // Log the error details for debugging
            console.error(`Attempt ${attempt} - Error parsing Azure OpenAI API response:`, error);

            // If the maximum number of attempts has been reached, throw an error
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch a valid JSON response after ${maxRetries} attempts.`);
            }

            // Optionally, add a short delay before retrying (e.g., 500 ms)
            await new Promise((resolve) => setTimeout(resolve, 500));
        }
    }
};

import { STORE_ANSWER } from '../actions/types';
import AnswerModel from '../../models/AnswerModel';

const initialState = {};

// Reducer for answers
const answerReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_ANSWER:
            // console.log('storing', action.payload);
            const { questionId, paragraphs = [], modified = [], answerType } = action.payload;  // Use `answerType`
            
            // Create a unique key combining questionId and answerType
            const uniqueKey = `${questionId}-${answerType}`;
            
            // Store or update the answer with the unique key
            return {
                ...state,
                [uniqueKey]: new AnswerModel(questionId, paragraphs, modified, answerType),
            };
        default:
            return state;
    }
};

export default answerReducer;

import { SUBMIT_QUESTION, DELETE_QUESTION } from './types';
import QuestionModel from '../../models/QuestionModel';

// Action to submit a new card (karteikarte)
export const submitNewCard = (cardText, cardId) => (dispatch) => {
  const newCard = new QuestionModel(cardId, cardText);

  // Dispatch the new card to the question reducer
  dispatch({
    type: SUBMIT_QUESTION,
    payload: newCard,
  });
};

// Action to delete a card
export const deleteQuestion = (questionId) => ({
  type: DELETE_QUESTION,
  payload: questionId,
});

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // For storing in localStorage
import logger from 'redux-logger';
import { combineReducers } from 'redux';
import questionReducer from './reducers/questionReducer';
import answerReducer from './reducers/answerReducer';

// Combine the question and answer reducers into one rootReducer
const rootReducer = combineReducers({
    questions: questionReducer,
    answers: answerReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['questions', 'answers'], // Persist both questions and answers
};

// Use persistReducer to wrap the rootReducer with persistence logic
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Conditionally add logger middleware only in development
const middleware = (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
        return getDefaultMiddleware({
            serializableCheck: false, // Disabling serializable checks for redux-persist
        }).concat(logger); // Adding redux-logger for debugging
    }
    return getDefaultMiddleware({
        serializableCheck: false,
    });
};

// Configure the store with Redux Toolkit
export const store = configureStore({
    reducer: persistedReducer,
    middleware,
});

export const persistor = persistStore(store);

class AnswerModel {
    constructor(questionId, paragraphs = [], modified = [], answerType) {
        this.questionId = questionId;
        this.paragraphs = Array.isArray(paragraphs) ? paragraphs : [];  // Ensure paragraphs is an array
        this.modified = modified.length ? modified : this.paragraphs.map(() => false); // Default to false for each paragraph
        this.answerType = answerType;  // Renamed from `type` to `answerType`
    }
}

export default AnswerModel;

import React, { useState } from 'react';
import { Menu, MenuItem, Button, Box, ListItemIcon, ListItemText } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckIcon from '@mui/icons-material/Check';

const NestedMenu = ({ onFilterSelect }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const [selectedMainOption, setSelectedMainOption] = useState(null);
    const [selectedOption, setSelectedOption] = useState({ main: '', sub: 'original' }); // Default to original answer

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSubMenuAnchorEl(null);
    };

    const handleSubMenuOpen = (event, option) => {
        setSubMenuAnchorEl(event.currentTarget);
        setSelectedMainOption(option);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
    };

    const handleOptionClick = (mainOption, subOption) => {
        setSelectedOption({ main: mainOption, sub: subOption });
        onFilterSelect(mainOption, subOption);
        handleMenuClose();
    };

    return (
        <Box>
            <Button
                variant="outlined"
                onClick={handleMenuOpen}
                sx={{ mt: 2, ml: 2 }}
            >
                Filter
            </Button>

            {/* Main Menu */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {/* Original Answer Option */}
                <MenuItem onClick={() => handleOptionClick('', 'original')}>
                    <ListItemText primary="Original" />
                    {selectedOption.sub === 'original' && (
                        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                            <CheckIcon fontSize="small" />
                        </ListItemIcon>
                    )}
                </MenuItem>

                {/* Schwierigkeitsgrad Menu */}
                <MenuItem onMouseEnter={(event) => handleSubMenuOpen(event, 'Schwierigkeitsgrad')}>
                    Schwierigkeitsgrad
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                </MenuItem>
                <Menu
                    anchorEl={subMenuAnchorEl}
                    open={Boolean(subMenuAnchorEl) && selectedMainOption === 'Schwierigkeitsgrad'}
                    onClose={handleSubMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {['einfach', 'mittel', 'fortgeschritten'].map((subOption) => (
                        <MenuItem
                            key={subOption}
                            onClick={() => handleOptionClick('Schwierigkeitsgrad', subOption)}
                        >
                            <ListItemText primary={subOption.charAt(0).toUpperCase() + subOption.slice(1)} />
                            {selectedOption.main === 'Schwierigkeitsgrad' && selectedOption.sub === subOption && (
                                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                    <CheckIcon fontSize="small" />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ))}
                </Menu>

                {/* Ausdrucksweise Menu */}
                <MenuItem onMouseEnter={(event) => handleSubMenuOpen(event, 'Ausdrucksweise')}>
                    Ausdrucksweise
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                </MenuItem>
                <Menu
                    anchorEl={subMenuAnchorEl}
                    open={Boolean(subMenuAnchorEl) && selectedMainOption === 'Ausdrucksweise'}
                    onClose={handleSubMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {['simpel', 'formell', 'wissenschaftlich'].map((subOption) => (
                        <MenuItem
                            key={subOption}
                            onClick={() => handleOptionClick('Ausdrucksweise', subOption)}
                        >
                            <ListItemText primary={subOption.charAt(0).toUpperCase() + subOption.slice(1)} />
                            {selectedOption.main === 'Ausdrucksweise' && selectedOption.sub === subOption && (
                                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                    <CheckIcon fontSize="small" />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ))}
                </Menu>

                {/* Thema Menu */}
                <MenuItem onMouseEnter={(event) => handleSubMenuOpen(event, 'Thema')}>
                    Thema
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                </MenuItem>
                <Menu
                    anchorEl={subMenuAnchorEl}
                    open={Boolean(subMenuAnchorEl) && selectedMainOption === 'Thema'}
                    onClose={handleSubMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {['popkultur', 'politik', 'fußball', 'tennis', 'musiker', 'gaming', 'scifi', 'fantasy', 'comics', 'reisen', 'mode', 'auto'].map((subOption) => (
                        <MenuItem
                            key={subOption}
                            onClick={() => handleOptionClick('Thema', subOption)}
                        >
                            <ListItemText primary={subOption.charAt(0).toUpperCase() + subOption.slice(1)} />
                            {selectedOption.main === 'Thema' && selectedOption.sub === subOption && (
                                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                    <CheckIcon fontSize="small" />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ))}
                </Menu>

                {/* Auslegungsmethode Menu */}
                {/* <MenuItem onMouseEnter={(event) => handleSubMenuOpen(event, 'Auslegungsmethode')}>
                    Auslegungsmethode
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                </MenuItem>
                <Menu
                    anchorEl={subMenuAnchorEl}
                    open={Boolean(subMenuAnchorEl) && selectedMainOption === 'Auslegungsmethode'}
                    onClose={handleSubMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {['wörtlich', 'systematisch', 'teleologisch', 'historisch'].map((subOption) => (
                        <MenuItem
                            key={subOption}
                            onClick={() => handleOptionClick('Auslegungsmethode', subOption)}
                        >
                            <ListItemText primary={subOption.charAt(0).toUpperCase() + subOption.slice(1)} />
                            {selectedOption.main === 'Auslegungsmethode' && selectedOption.sub === subOption && (
                                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                    <CheckIcon fontSize="small" />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ))}
                </Menu> */}
            </Menu>
        </Box>
    );
};

export default NestedMenu;
